@import "../../../_variables.scss";

//a {
//  &:hover,
//  &:focus {
//    text-decoration: none;
//  }
//}
//
//h6 {
//  font-weight: normal;
//}

.conversations {
  border-right: $border-default-base;
  flex-basis: 450px;
  max-width: 450px;
  flex-shrink: 0;
  background: var(--text-on-color);
}

.conversation-list {
  display: flex;
  flex-direction: column;
  background: $bg-main;
  overflow-x: hidden;

  &-spinner {
    display: flex;
    justify-content: center;
  }

  & > * {
    border-bottom: $border-default-base;
    margin: 0;
    flex-shrink: 0;
  }
}

//
//::-webkit-scrollbar {
//  width: 7px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: rgb(90, 90, 90);
//}
//
//::-webkit-scrollbar-track {
//  background: rgba(0, 0, 0, 0.2);
//}

.conversation-section-title {
  padding: 20px 30px;

  img {
    margin-right: 10px;
    width: 20px;
  }
}

.conversation-list-item {
  padding: 20px 30px;
  display: flex;

  &-flag {
    img {
      margin-top: -5px;
      width: 15px;
    }
  }

  &-tickets {
    padding: 40px 60px;
  }

  //&:hover {
  //  background: #eaf9fd;
  //}

  //&:global(.active) {
  //  background: $bg-base;
  //  box-shadow: inset 3px 0 0 0 #e5515b;
  //}

  &-avatars {
    margin-right: 1rem;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;

    & > *:nth-child(2) {
      position: relative;
      top: -15px;
      left: 10px;
    }
  }

  &-content {
    flex-grow: 1;
  }

  &-header {
    & > * {
      margin: 0;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &-title {
    color: #3e3f42;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-transform: uppercase;

    &:hover {
      color: #e5515b;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &-time {
    font-size: 12px;
    color: #9ea0a5;
    line-height: 1.125rem;
    text-align: right;
  }

  &-message {
    color: #6b6c6f;
    flex-grow: 1;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -o-text-overflow: ellipsis;
  }

  &-body {
    margin-top: 5px;
    display: flex;
  }

  &-buttons {
    color: var(--text-main);
    font-size: 0.9rem;
    font-weight: bold;
    position: relative;
    margin-right: 30px;
    width: 175px;

    &-dropdown {
      font-weight: normal;
      background: #ffffff;
      margin-top: 10px;
      position: absolute;
      min-width: 100px;
      left: -10px;

      div {
        padding: 5px 10px;

        &:hover {
          color: #e5515b;
        }
      }
    }
  }

  &-notification {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    flex-basis: 20px;
    flex-shrink: 0;
    margin-left: 8px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-on-color;
    font-size: 0.75rem;
  }

  &-footer {
    border-top: $border-default-base;
    margin-top: 20px;
    padding-top: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-building-image {
    height: 26px;
    width: 26px;
    border-radius: 4px;
    @include backgroundCover();
    flex-shrink: 0;
    margin-right: 14px;
  }

  &-building-name {
    font-weight: 500;
    font-size: 0.875rem;
    color: $text-base;
  }
}
